var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-title",
    [
      _c(
        "v-avatar",
        {
          staticClass: "mr-6",
          attrs: { color: "primary", size: "60", rounded: "lg" }
        },
        [
          _c("v-icon", { staticClass: "white--text", attrs: { size: "45" } }, [
            _vm._v(" mdi-account-circle")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-column mb-3 align-start" },
        [
          _c("h2", { staticClass: "primary--text mb-2" }, [
            _vm._v(" " + _vm._s(_vm.applicant.name) + " ")
          ]),
          _c(
            "v-chip",
            {
              staticClass: "justify-center",
              attrs: { color: "secondary", small: "" }
            },
            [
              _c("span", { staticClass: "text-uppercase font-weight-bold" }, [
                _vm._v(_vm._s(_vm.applicant.type))
              ])
            ]
          )
        ],
        1
      ),
      _c("v-spacer"),
      _c("v-divider", { staticClass: "mr-2", attrs: { vertical: "" } }),
      _c("div", [
        _c("h5", { staticClass: "mb-2 text-uppercase primary--text" }, [
          _vm._v(" Report Completed By: ")
        ]),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-1", attrs: { color: "grey lighten-1" } },
              [_vm._v("mdi-account")]
            ),
            _c(
              "strong",
              {
                staticClass:
                  "text-uppercase text-subtitle-2 grey--text text--darken-2"
              },
              [_vm._v(_vm._s(_vm.assignedTo.name))]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }