<template>
    <v-card-title>
        <v-avatar class="mr-6" color="primary" size="60" rounded="lg">
            <v-icon size="45" class="white--text"> mdi-account-circle</v-icon>
        </v-avatar>
        <div class="d-flex flex-column mb-3 align-start">
            <h2 class="primary--text mb-2">
                {{ applicant.name }}
            </h2>
            <v-chip color="secondary" class="justify-center" small>
                <span class="text-uppercase font-weight-bold">{{
                    applicant.type
                }}</span>
            </v-chip>
        </div>
        <v-spacer></v-spacer>
        <v-divider vertical class="mr-2"></v-divider>
        <div>
            <h5 class="mb-2 text-uppercase primary--text">
                Report Completed By:
            </h5>
            <div class="d-flex align-center">
                <v-icon class="mr-1" color="grey lighten-1">mdi-account</v-icon
                ><strong
                    class="text-uppercase text-subtitle-2 grey--text text--darken-2"
                    >{{ assignedTo.name }}</strong
                >
            </div>
        </div>
    </v-card-title>
</template>
<script>
export default {
    name: "applicant-profile-header",
    components: {},
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        assignedTo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>